import React from 'react'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'
import StyledLink from './styled-link'

export default ({ data }) => {
  const blogs = data.blogs.edges

  return (
    <aside
      css={css`
        width: 40%;
        height: 60vh;
        overflow: auto;
        border: 1px dashed #0190b6;
        margin: 0 ${rhythm(1 / 4)} 0;
        padding: ${rhythm(1 / 4)} ${rhythm(1 / 4)} 0;
      `}
    >
      <h6>{blogs.count} Posts</h6>
      {blogs.map(({ node }) => (
        <div key={node.childMarkdownRemark.fields.slug}>
          <small
            css={css`
              margin: 0;
              color: #0190b6;
              font-weight: lighter;
            `}
          >
            {node.childMarkdownRemark.frontmatter.date}
          </small>
          <div
            css={css`
              margin: 0;
            `}
          >
            <StyledLink
              to={node.childMarkdownRemark.fields.slug}
              where={node.childMarkdownRemark.frontmatter.title}
              color="#0190b6"
              hover="white"
            />
          </div>
          <small>{node.childMarkdownRemark.excerpt}</small>
          <hr
            css={css`
              margin: ${rhythm(1 / 4)} 0;
            `}
          />
        </div>
      ))}
    </aside>
  )
}
