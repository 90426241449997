import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { css } from '@emotion/core';
import { rhythm } from '../utils/typography';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import SidePanel from '../components/side-panel';

export default ({ data }) => {
	const main = data.main.edges;
	//const blogs = data.blogs.edges;

	return (
		<Layout>
			<SEO title='Home' />
			<main>
				<Carousel />
				<div
					css={css`
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						text-align: left;
						flex: 1;
					`}
				>
					<section
						css={css`
							width: 60%;
							margin: 0 ${rhythm(1 / 4)} 0;
						`}
					>
						<h3
							dangerouslySetInnerHTML={{
								__html: main[0].node.childMarkdownRemark.frontmatter.title
							}}
						/>
						<span
							dangerouslySetInnerHTML={{
								__html: main[0].node.childMarkdownRemark.html
							}}
						/>
					</section>
					<SidePanel data={data} />
				</div>
			</main>
		</Layout>
	);
};
export const query = graphql`
	query {
		blogs: allFile(
			sort: { fields: [birthtime], order: DESC }
			filter: { name: { glob: "blog-*" } }
		) {
			edges {
				node {
					name
					childMarkdownRemark {
						frontmatter {
							title
							date(formatString: "MMM Do YYYY")
						}
						fields {
							slug
						}
						excerpt(pruneLength: 1000)
					}
				}
			}
		}
		main: allFile(filter: { name: { glob: "section-*" } }) {
			edges {
				node {
					name
					childMarkdownRemark {
						frontmatter {
							title
						}
						fields {
							slug
						}
						html
					}
				}
			}
		}
	}
`;
