import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpeg|jpg|png)/" }
            sourceInstanceName: { eq: "carousel" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Slider
        arrows={false}
        swipeToSlide={true}
        pauseOnHover={true}
        autoplay={true}
        autoplaySpeed={5000}
        infinite={true}
        draggable={true}
      >
        {data.allFile.edges.map(edge => (
          <div key={edge.node.childImageSharp.fluid.src}>
            <img src={`${edge.node.childImageSharp.fluid.src}`} alt="" />
          </div>
        ))}
      </Slider>
    )}
  />
)
